import QrCodeDemo from "@assets/images/qr-code-demo.png"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { createQrCodeApi } from "src/api/myBalance"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { useAuth } from "src/customHook/useAuth"
import { useCountTime } from "src/customHook/useCountTime"
import useIsMobile from "src/customHook/useIsMobile"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import { listDefaultAvt } from "../MyPage/config"
type TGENDER = "MALE" | "FEMALE"

type Props = {}

interface QrCode {
  expiredAt: string
  qrId: string
  qrUrl: string
}

function MyQR({}: Props) {
  const { t, i18n } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const [qrCode, setQrCode] = useState<QrCode>()
  const { countTime, handleSetCountTime } = useCountTime(0)
  const [isNotEnoughVoucher, setIsNotEnoughVoucher] = useState<boolean>(false)
  const dataUser = useAppSelector(userDataSelect)
  const genderType: TGENDER = dataUser?.gender
  const dispatch = useAppDispatch()

  const createQrCode = async () => {
    try {
      const { data, error }: any = await createQrCodeApi()
      if (error?.errorCode === "NOT_ENOUGH_VOUCHER") {
        setIsNotEnoughVoucher(true)
        return
      }
      console.log("qrcode",data)
      setQrCode(data)
    
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (countTime === 0) {
      createQrCode()
    }
  }, [countTime])

  useEffect(() => {
    qrCode && handleSetCountTime(60)
  }, [qrCode])

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  return (
    <div className="lg:pt-[60px] lg:pb-[74px] xl:pb-[52px]">
      {isMobile && (
        <div className="relative">
          <NavBarMb pageName="MY월렛" />
        </div>
      )}
      <div className="lg:container">
        {!isMobile && (
          <h2 className="text-[24px] font-pretendard700 leading-[100%] mb-[26px]">
            {t("MY 월렛")}
          </h2>
        )}
        <div className="relative">
          <div className="z-10 bg-[#232323] md:w-full bg-cover bg-no-repeat">
            <div className="flex flex-col items-center relative before:absolute lg:pt-[71px] pt-[98px] lg:pb-[93px] pb-[81px] px-[16px]">
              <div className="grid lg:gap-[15px] gap-[10px] justify-center">
                <img
                  className="lg:w-[64px] w-[28px] lg:h-[64px] h-[28px] rounded-full m-auto"
                  src={dataUser?.avatar || listDefaultAvt[genderType]}
                  alt="avt"
                />
                <p className="lg:text-[18px] text-[16px] text-white font-pretendard700 leading-[100%] mb-[31px]">
                  {t("QR code [displayName]", {
                    displayName: dataUser?.displayName,
                  })}
                </p>
              </div>
              {isNotEnoughVoucher ? (
                <p className="text-center lg:text-[16px] text-[14px] text-[#D4D4D4] lg:leading-[25.6px] leading-[16.8px] lg:pt-[120px] pt-[50px] lg:pb-[199px] pb-[60px]">
                  현재 {dataUser?.displayName}님은 이용 중인 멤버십이 없습니다.
                </p>
              ) : (
                <div className="flex flex-col items-center">
                  <img
                    src={isMobile ? qrCode?.qrUrl : QrCodeDemo}
                    alt=""
                    className="lg:w-[240px] lg:h-[240px] w-[160px] h-[160px]"
                  />
                  <div className="h-[23px] w-[33px] m-auto px-[8px] border border-white rounded-[999px] lg:mt-[27px] mt-[32px] lg:mb-[34px] mb-[25px] flex items-center justify-center">
                    <span className="block text-[12px] text-white">
                      {countTime}
                    </span>
                  </div>
                  {isMobile ? (
                    <div className="text-center text-white lg:text-[16px] text-[14px] lg:leading-[25.6px] leading-[16.8px]">
                      <p className="text-[#D4D4D4]">
                        {t("QR 코드를 매장 직원에게 보여주고")}
                      </p>
                      {/* <p className="text-[#D4D4D4]">
                        {t("결제를 완료하세요.")}
                      </p> */}
                      {/* <Link
                        className="mt-[26px] block underline text-[#A3A3A3]"
                        to="#"
                      >
                        가이드 보기
                      </Link> */}
                    </div>
                  ) : (
                    <div className="text-center text-white lg:text-[16px] text-[14px] text-[#D4D4D4] lg:leading-[25.6px] leading-[16.8px]">
                      <p className="text-[#D4D4D4]">
                        {t("모아프렌즈의 VIP 멤버십 QR코드로")}
                      </p>
                      <p className="text-[#D4D4D4]">
                        {t(
                          "해당 브랜드의 오프라인 매장을 이용하고 결제할 수 있어요",
                        )}
                      </p>
                      <p className="text-[#D4D4D4]">
                        모바일 화면에서 QR 코드를 확인할 수 있습니다
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyQR
