import React from "react";
import styles from "./BannerSlide.module.scss";
import './BannerSlide.scss'
// Swiper 관련 import
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface BannerItem {
  id: number;
  party: string;
  title: string;
  description: string;
  imageUrl: string;
  backgroundColor: string;
}
interface BannerSlideProps {
  bannerData: BannerItem[];
}

const BannerSlide: React.FC<BannerSlideProps> = ({ bannerData }) => {
  return (
    <div className={styles.container + " polibanner"}>
      <Swiper
        // Swiper 설정
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={50}    // 슬라이드간 간격
        slidesPerView={2}    // 한 번에 보여줄 슬라이드 개수

      >
        {bannerData.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              className={styles.slideContent}
              style={{ backgroundColor: item.backgroundColor }}
            >
              <div className={styles.info}>
                <span className={styles.party}>{item.party}</span>
                <h2 className={styles.title}>{item.title}</h2>
                <p className={styles.description}>{item.description}</p>
              </div>
              <div className={styles.imageWrapper}>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className={styles.image}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlide;