import React, { useState } from "react";
import styles from "./Ranking.module.scss";
import { Link } from "react-router-dom";

interface RankingItem {
  rank: number;
  name: string;
  arrow: string;
  profileImageUrl: string;
  link: string;
}

interface RankingData {
  influence: RankingItem[];
  rising: RankingItem[];
  activity: RankingItem[];
}

interface RankingProps {
  rankingData: RankingData;
}

const TABS = [
  { key: "influence", label: "영향력" },
  { key: "rising", label: "급상승" },
  { key: "activity", label: "활동성" },
];

const Ranking: React.FC<RankingProps> = ({ rankingData }) => {
  const [activeTab, setActiveTab] = useState<keyof RankingData>(TABS[0].key as keyof RankingData);

  // 현재 탭에 맞는 데이터를 가져옴
  const currentData = rankingData[activeTab] || [];

  return (
    <div className={styles.container}>
      {/* 탭 영역 */}
      <div className={styles.tabWrapper}>
        {TABS.map((tab) => (
          <button
            key={tab.key}
            className={
              tab.key === activeTab ? styles.activeTab : styles.tab
            }
            onClick={() => setActiveTab(tab.key as keyof RankingData)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* 타이틀 / 서브텍스트 영역 */}
      <div className={styles.headerArea}>
        <h2>우리 정치인의 영향력은?<span> (24.12.16 ~ 23)</span></h2>
        <p>우리 정치인 관련 지수는 언론보도와 커뮤니티, SNS 등의 데이터를 기반으로 계산됩니다. </p>
        <p>저희는 우리 정치인들의 영향력 트렌드를 객관적으로 반영하기 위해 최선을 다하고 있습니다. </p>
        <p className={styles.notice}>
          *당신의 알고리즘을 악용할 의심이 있기에 구체적인 기준을 공개하지 않습니다.
        </p>
      </div>

      {/* 랭킹 목록 */}
      <div className={styles.rankingList}>
        {currentData.map((item) => (
          <div key={item.rank} className={styles.rankingItem}>
            <div className={styles.profileImageWrapper}>
              <img
                src={item.profileImageUrl}
                alt={item.name}
                className={styles.profileImage}
              />
            </div>
            <Link to={item.link}>
            <div className={styles.info}>
              <div className={styles.rankText}>
                {item.rank}위
                {item.arrow === "up" && <span className={styles.upArrow}>▲</span>}
              {item.arrow === "down" && (
                  <span className={styles.downArrow}>▼</span>
                )}
              </div>
              <div className={styles.nameText}>{item.name}</div>
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ranking;