import IconCloseWhite from "@assets/icons/header/icon-close-white.svg"
import LogoMb from "@assets/images/logoMb.svg"
import { Drawer, DrawerProps } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { UrlInternal } from "src/constant/endpointRoute"
import { useAuth } from "src/customHook/useAuth"
import { close } from "src/features/drawer/drawerSlice"
import { show } from "src/features/header/headerSlice"
import { logout } from "src/features/user/userSlice"
import { userDataSelect } from "src/features/userData/userDataSlice"
import { menuList } from "src/layout/config"

type Props = {}

function DrawerMenu({ onClose, ...props }: Props & DrawerProps) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispath = useAppDispatch()
  const { isLogined, user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const dataUser = useAppSelector(userDataSelect)

  useEffect(() => {
    dispath(close())
    dispath(show())
  }, [dispath, location])

  return (
    <Drawer
      placement="right"
      {...props}
      onClose={() => {
        dispath(close())
      }}
      headerStyle={{ display: "none" }}
      bodyStyle={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
      contentWrapperStyle={{
        boxShadow: "none",
      }}
      width={327}
      className="ml-[48px]"
    >
      <div
        className="absolute top-[18px] left-[12px] cursor-pointer"
        onClick={() => {
          dispath(close())
        }}
      >
        <IconCloseWhite />
      </div>
      <div className="bg-primaryWhite px-[13px] py-[23px] flex flex-col justify-between h-[100vh]">
        <div className="">
          <Link className="mb-[34px] flex" to={"/"}>
            <LogoMb />
          </Link>
          <div className="px-[11px] flex flex-col gap-[21px]">
            {menuList.map((item, index) => {
              if (!isLogined && item.link === UrlInternal.MY_VOUCHERS) {
                return null;
              }

              if (
                isLogined &&
                !dataUser?.hasMyVouchers &&
                item.link === UrlInternal.MY_VOUCHERS
              ) {
                return null
              }

              return (
                <Link
                  key={index}
                  className="text-[16px] font-pretendard400"
                  to={item.link}
                >
                  {t(item.title)}
                </Link>
              )
            })}
          </div>
        </div>
        <div className="mt-[16px] mb-[37px] px-[13px] flex flex-col gap-[21px]">
          {/* <Link className="text-[16px] font-pretendard400" to={"/auth"}>
            {isLogined ? "로그인" : "로그아웃"}
          </Link> */}

          {isLogined ? (
            <div
              className="text-[16px] font-pretendard400"
              onClick={() => dispath(logout())}
            >
              {t("로그아웃")}
            </div>
          ) : (
            <div
              className="text-[16px] font-pretendard400"
              onClick={() => {
                navigate({
                  pathname: "/auth",
                  search: `backUrl=${pathname}`,
                })
              }}
            >
              {t("로그인")}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerMenu
