import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import Button from "src/components/Button"
import Selection from "src/components/Selection"
import RightArrowIcon from "@assets/icons/right-arrow-new.svg"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"

type FormState = {
  terms: boolean
  privacy: boolean
  already: boolean
}

type Props = {}

function RegisterForm({}: Props) {
  const { isMobile } = useIsMobile(1023)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [formState, setFormState] = useState<FormState>({
    terms: false,
    privacy: false,
    already: false,
  })

  const handleOnSelectChange = (checked: Record<string, boolean>) => {
    setFormState((prev) => ({ ...prev, ...checked }))
  }

  return (
    <>
      {isMobile && <NavBarMb pageName="약관 동의" />}
      <div className="relative w-full max-w-[543px] m-auto lg:pt-[81px] pt-[62px] pb-[127px] px-[24px]">
        <h2 className="text-center text-black text-[24px] font-pretendard500 leading-normal mb-[8px]">
          {t("약관 동의")}
        </h2>
        <p className="text-center text-gray1 text-[16px] font-pretendard400 leading-[100%] mb-[41px]">
          {t("Register to Membership to use various service in our eco-system")}
        </p>
        <form action="">
          <h3 className="text-black text-[16px] font-pretendard700 leading-normal mb-[16px]">
            {t("필수 동의 항목")}
          </h3>
          {["terms", "privacy"].map((item) => (
            <div key={item} className="mb-[20px] flex items-center">
              <Selection
                width={23}
                height={23}
                borderColor="#A3A3A3"
                checkmarkSize={16}
                labelStyle={{
                  fontSize: 16,
                  color: "#000",
                  fontFamily: "Pretendard-400",
                }}
                onSelectChange={(checked) => handleOnSelectChange(checked)}
                selectOption={[
                  {
                    label: t(
                      `(필수) ${
                        item === "terms" ? "이용약관" : "개인정보 수집 동의"
                      }`,
                    ),
                    name: item,
                    defaultChecked: formState[item as keyof FormState],
                  },
                ]}
              />
              <Link
                className="mt-[3px] cursor-pointer"
                to={item === "terms" ? "/terms-of-use" : "/privacy-policy"}
                target={isMobile ? "_self" : "_blank"}
              >
                <RightArrowIcon />
              </Link>
            </div>
          ))}
          <h3 className="text-black text-[16px] font-pretendard700 leading-normal mt-[20px]">
            {t("선택 동의 항목")}
          </h3>
          <div className="mt-[20px] flex items-center">
            <Selection
              borderColor="#A3A3A3"
              width={23}
              height={23}
              checkmarkSize={16}
              labelStyle={{
                fontSize: 16,
                color: "#000",
                fontFamily: "Pretendard-400",
              }}
              onSelectChange={(checked) => handleOnSelectChange(checked)}
              selectOption={[
                {
                  label: t("(선택) 마케팅 수신 동의"),
                  name: "already",
                  defaultChecked: formState.already,
                },
              ]}
            />
            {/* <Link className="mt-[3px] cursor-pointer" to="#" target="_blank">
              <RightArrowIcon />
            </Link> */}
          </div>
          <div className="mt-[45px]">
            {/* <Button
              theme="main"
              onClick={() => {
                navigate("/auth/niceApi-form", {
                  state: { requestType: "REGISTER" },
                })
              }}
              disabled={!formState.terms || !formState.privacy}
            >
              {t("다음단계")}
            </Button> */}
          <Button
            theme="main"
            onClick={() => {
              navigate("/auth/create-profile")
            }}
            disabled={!formState.terms || !formState.privacy}
            >
            {t("다음단계")}
          </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default RegisterForm
