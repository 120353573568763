import { ENV } from "./constraints"

export default function ApiConfig() {
  const configs = {
    development: {
      // BASE_URL: process.env.REACT_APP_DEV_SERVER_RESERVE_URL
      BASE_URL: import.meta.env.VITE_API_URL,
    },
    staging: {
      // BASE_URL: process.env.REACT_APP_DEV_SERVER_RESERVE_URL
      BASE_URL: import.meta.env.VITE_API_URL,
    },
    production: {
      BASE_URL: import.meta.env.VITE_API_URL,
    },
  }
  console.log("configs", configs)
  return configs?.[ENV]
}
