import en from "@assets/locales/en/translation.json"
import kor from "@assets/locales/kor/translation.json"
import { ConfigProvider } from "antd"
import i18n from "i18next"
import moment from "moment"
import { useEffect } from "react"
import { initReactI18next, useTranslation } from "react-i18next"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { ctrl } from "src/services/SSEEvent"
import "swiper/css"
import "./App.css"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import DrawerMenu from "./components/DrawerMenu"
import TossPayMembership from "./components/Modal/Brand/TossPayMembership"
import InsufficientBalance from "./components/Modal/InsufficientBalance"
import ConfirmModal from "./components/Modal/PaymentModal/ConfirmModal"
import PaymentSuccess from "./components/Modal/PaymentModal/PaymentSuccess"
import TransactionExpired from "./components/Modal/TransactionExpired"
import ScrollTop from "./components/ScrollTop"
import { UrlInternal } from "./constant/endpointRoute"
import useIsMobile from "./customHook/useIsMobile"
import SearchPopup from "./features/Search/components/SearchPopup"
import SearchDesktop from "./features/Search/page/SearchDesktopResult"
import SearchMobile from "./features/Search/page/SearchMobileResult"
import { getBrandDetailData } from "./features/brandData/brandDataSlice"
import {
  openConfirmTransaction,
  openVerifyOrder,
} from "./features/confirmTransaction/confirmTransactionSlice"
import { loginSuccess, logout, tokenSelect } from "./features/user/userSlice"
import MainLayout from "./layout"
import AuthLayout from "./layout/AuthLayout"
import CreateProfile from "./pages/Auth/CreateProfile"
import UserAuth from "./pages/Auth/LoginRegister"
import RegisterForm from "./pages/Auth/LoginRegister/RegisterForm"
import NiceApiForm from "./pages/Auth/NiceApiForm"
import PrivacyPolicy from "./pages/Auth/PrivacyPolicy"
import ResetPassword from "./pages/Auth/ResetPassword"
import SupplierInfo from "./pages/Auth/SupplierInfo"
import TermsOfUse from "./pages/Auth/TermsOfUse"
import Brand from "./pages/Brand"
import BrandDetail from "./pages/BrandDetail"
import CommentPageMobile from "./pages/BrandDetail/Community/components/CommentPageMobile"
import PostPageMobile from "./pages/BrandDetail/Community/components/PostPageMobile"
import ReportPageMobile from "./pages/BrandDetail/Community/components/ReportPageMobile"
import DetailFeedMobile from "./pages/BrandDetail/Feed/DetailFeedMobile"
import FAQ from "./pages/FAQ"
import GroupBuying from "./pages/GroupBuying"
import OrderSummary from "./pages/GroupBuying/OrderSummary"
import Close from "./pages/GroupBuying/OrderSummary/Close"
import TossPay from "./pages/GroupBuying/OrderSummary/TossPay"
import VoucherDetail from "./pages/GroupBuying/VoucherDetail"
import VoucherList from "./pages/GroupBuying/VoucherList"
import HomePage from "./pages/Home"
import LangdingPage from "./pages/Langdingpage"
import MyBrandPage from "./pages/MyBrand"
import MyPage from "./pages/MyPage"
import Inquiry from "./pages/MyPage/Inquiry"
import MyChangeInfo from "./pages/MyPage/MyChangeInfo"
import MyMembership from "./pages/MyPage/MyMembership"
import MyMembershipDetail from "./pages/MyPage/MyMembershipDetail"
import HistoryUsingMembership from "./pages/MyPage/MyMembershipDetail/pages/HistoryUsingMembership"
import MyQR from "./pages/MyQR"
import NotFound from "./pages/NotFound"
import NotLogin from "./pages/NotLogin"
import NotLoginInactive from "./pages/NotLoginInactive"
import OrderDetail from "./pages/OrderDetail"
import OrderHistory from "./pages/OrderHistory"
import { initForFetchEventSource } from "./services/SSEEvent"

import jwt_decode from "jwt-decode"
import SuccessTransaction from "./components/Modal/MyVoucherModal/SuccessTransaction"
import VerifyOrderModal from "./components/Modal/MyVoucherModal/VerifyOrderModal"
import BrandShop from "./pages/BrandShop"
import BrandShopDetail from "./pages/BrandShop/BrandShopDetail"
import BrandShopOrder from "./pages/BrandShop/BrandShopOrder"
import TossPayProduct from "./pages/BrandShop/BrandShopOrder/TossPayProduct"
import HistoryReceiedGift from "./pages/MyPage/HistoryReceiedGift"
import DetailHistoryReceivedGift from "./pages/MyPage/HistoryReceiedGift/DetailHistoryReceivedGift"
import MyEContent from "./pages/MyPage/MyEContent"
import MyEContentDetail from "./pages/MyPage/MyEContent/MyEContentDetail"
import CancelMembership from "./pages/MyPage/MyMembershipDetail/pages/CancelMembership"
import CommunityMembership from "./pages/MyPage/MyMembershipDetail/pages/CommunityMembership"
import HistoryOrderMembership from "./pages/MyPage/MyMembershipDetail/pages/HistoryOrderMembership"
import DetailHistoryOrderMembership from "./pages/MyPage/MyMembershipDetail/pages/HistoryOrderMembership/DetailHistoryOrderMembership"
import MyOrderHistory from "./pages/MyPage/MyOrderHistory"
import OrderEcontentDetail from "./pages/MyPage/MyOrderHistory/OrderEcontentDetail"
import OrderProductDetail from "./pages/MyPage/MyOrderHistory/OrderProductDetail"
import OrderVoucherDetail from "./pages/MyPage/MyOrderHistory/OrderVoucherDetail"
import Notification from "./pages/MyPage/Notification"
import DetailNotification from "./pages/MyPage/Notification/DetailNotification"
import VoucherUsageHistory from "./pages/MyPage/VoucherUsageHistory"
import DetailVoucherUsageHistory from "./pages/MyPage/VoucherUsageHistory/DetailVoucherUsageHistory"
import MyVouchers from "./pages/MyVouchers"
import ReceivedGift from "./pages/ReceivedGift"
import SearchFilterBrand from "./pages/SearchFilterBrand"
import PopupAdmin from "./components/PopupAdmin"

// new Home Page
import NewHomePage from "./pages/NewHome"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      kor: {
        translation: kor,
      },
    },
    lng: "kor", // if you're using a language detector, do not define the lng option
    fallbackLng: "kor",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
function App() {
  const accessToken = useAppSelector(tokenSelect)
  const { isMobile } = useIsMobile(1023)
  const drawer: any = useAppSelector((state) => state.drawer)
  const user: any = useAppSelector((state) => state.user)
  const { detailData }: any = useAppSelector((state) => state.brandData)
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get("accessToken")

  useEffect(() => {
    if (token) {
      const decoded: any = jwt_decode(token)

      dispatch(loginSuccess({ ...decoded, accessToken: token }))
    }
  }, [dispatch, token])

  useEffect(() => {
    if (!!user.currentUser) {
      initForFetchEventSource(accessToken, {
        onRequestConfirm: ({ data }) => {
          if (user?.currentUser?.accessToken) {
            const dataEv = JSON.parse(data)

            console.log("dataEv", dataEv)
            if (dataEv?.logout) {
              dispatch(logout())
            }

            const dataPayment = {
              trxUID: dataEv.trxUID,
              branchNote: dataEv?.branchNote,
              expiredAt: dataEv?.expiredAt,
              amount: dataEv.amount,
              time: dataEv?.time,
              branchId: dataEv.branchId,
              brandId: dataEv.brandId,
              branchInformation: {
                lat: dataEv.branchInformation.lat,
                long: dataEv.branchInformation.long,
                address: dataEv.branchInformation.address,
              },
              ...dataEv,
            }

            if (dataEv?.amount > 0) {
              if (dataEv.brandId !== detailData?.id) {
                dispatch(getBrandDetailData(dataEv.brandId))
              }
              if (dataEv.status === "REQUEST_USING_VOUCHER_COUPON") {
                dispatch(openVerifyOrder(dataPayment))
              } else {
                dispatch(openConfirmTransaction(dataPayment))
              }
            }
          }
        },
      })
    } else {
      ctrl.abort()
    }
  }, [accessToken])

  console.log("i18n.language", i18n.language)

  useEffect(() => {
    moment.locale("en", {
      relativeTime: {
        // future: i18n.language === "kor" ? "방금전" : "just now",
        // past: i18n.language === "kor" ? "방금전" : "just now",
        // future: "in %s",
        past: i18n.language === "kor" ? "%s 전" : "%s ago",
        s: i18n.language === "kor" ? "초" : "seconds",
        ss: i18n.language === "kor" ? "%s초" : "%ss",
        m: i18n.language === "kor" ? "1분" : "a minute",
        mm: i18n.language === "kor" ? "%d분" : "%dm",
        h: i18n.language === "kor" ? "1시간" : "1h",
        hh: i18n.language === "kor" ? "%d시간" : "%dh",
        d: i18n.language === "kor" ? "1일" : "a day",
        dd: i18n.language === "kor" ? "%d일" : "%dd",
        M: "a month",
        MM: "%dM",
        y: "a year",
        yy: "%dY",
      },
    })
  }, [i18n.language])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
        },
      }}
    >
      <BrowserRouter>
        {/* <PopupAdmin /> */}

        <ScrollTop />
        <PaymentSuccess />
        <ConfirmModal />
        <InsufficientBalance />
        <TransactionExpired />

        {/**My voucher */}
        <VerifyOrderModal />
        <SuccessTransaction />

        <SearchPopup />
        <DrawerMenu open={drawer.open} />
        <Routes>
          <Route path="/close" element={<Close />} />
          <Route path="" element={<MainLayout />}>
            <Route path="new" element={<NewHomePage />} />
            <Route path="" element={<HomePage />} />
            <Route element={<AuthLayout />}>
              <Route
                path={`${UrlInternal.RECEIVED_GIFT}/:slug`}
                element={<ReceivedGift />}
              />
              <Route path="qr-code" element={<MyQR />} />
              <Route path="my-page" element={<MyPage />}>
                <Route path="" element={<MyMembership />} />
                <Route path="my-change-info" element={<MyChangeInfo />} />
                <Route path="inquiry" element={<Inquiry />} />
                <Route path="order-history" element={<OrderHistory />} />
                <Route path="order-detail/:id" element={<OrderDetail />} />
                <Route
                  path={`${UrlInternal.HISTORY_RECEIVED_GIFT}/:id`}
                  element={<DetailHistoryReceivedGift />}
                />
                <Route
                  path={UrlInternal.NOTIFICATION}
                  element={<Notification />}
                />
                <Route
                  path={`${UrlInternal.NOTIFICATION}/:id`}
                  element={<DetailNotification />}
                />

                <Route
                  path={UrlInternal.ORDER_VOUCHER_DETAIL + "/:id"}
                  element={<OrderVoucherDetail />}
                />
                <Route
                  path={UrlInternal.ORDER_ECONTENT_DETAIL + "/:id"}
                  element={<OrderEcontentDetail />}
                />
                <Route
                  path={UrlInternal.ORDER_PRODUCT_DETAIL + "/:id"}
                  element={<OrderProductDetail />}
                />
                <Route
                  path={UrlInternal.MY_E_CONTENT + "/:id"}
                  element={<MyEContentDetail />}
                />
              </Route>

              <Route
                path="my-membership/:id"
                element={<MyMembershipDetail />}
              />
              <Route
                path={UrlInternal.CANCEL_MEMBER_SHIP}
                element={<CancelMembership />}
              />
              <Route
                path={UrlInternal.MY_MEMBERSHIP}
                element={<MyBrandPage />}
              />
              <Route
                path={UrlInternal.HISTORY_USING_MEMBERSHIP}
                element={<HistoryUsingMembership />}
              />
              <Route
                path={UrlInternal.COMMUNITY_MEMBERSHIP}
                element={<CommunityMembership />}
              />
              <Route
                path={UrlInternal.HISTORY_ORDER_MEMBERSHIP}
                element={<HistoryOrderMembership />}
              />
              <Route
                path={UrlInternal.HISTORY_RECEIVED_GIFT}
                element={<HistoryReceiedGift />}
              />
              <Route path={UrlInternal.MY_VOUCHERS} element={<MyVouchers />} />

              <Route
                path={`${UrlInternal.DETAIL_HISTORY_ORDER_MEMBERSHIP}/:id`}
                element={<DetailHistoryOrderMembership />}
              />

              <Route
                path={UrlInternal.MY_ORDER_HISTORY}
                element={<MyOrderHistory />}
              />
              <Route path={UrlInternal.MY_E_CONTENT} element={<MyEContent />} />
              <Route
                path={UrlInternal.VOUCHER_USAGE_HISTORY}
                element={<VoucherUsageHistory />}
              />
              <Route
                path={UrlInternal.VOUCHER_USAGE_HISTORY + "/:id"}
                element={<DetailVoucherUsageHistory />}
              />
            </Route>

            <Route path={UrlInternal.BRAND_SHOP} element={<BrandShop />}>
              <Route path="detail">
                <Route path=":id" element={<BrandShopDetail />} />
              </Route>
              <Route path="order" element={<AuthLayout></AuthLayout>}>
                <Route path=":id" element={<BrandShopOrder />} />
              </Route>
            </Route>

            <Route path="group-buying" element={<GroupBuying />}>
              <Route path="detail">
                <Route path=":id" element={<VoucherDetail />} />
              </Route>
              <Route path=":type" element={<VoucherList />} />
              <Route path="order" element={<AuthLayout></AuthLayout>}>
                <Route path=":id" element={<OrderSummary />} />
              </Route>
              <Route path="" element={<Navigate to={"all"} replace />} />
            </Route>
            <Route path="tosspay" element={<TossPay />} />
            <Route
              path={`${UrlInternal.TOSS_MEMBERSHIP}/:id`}
              element={<TossPayMembership />}
            />
            <Route
              path={`${UrlInternal.TOSS_PRODUCT}/:id`}
              element={<TossPayProduct />}
            />
            <Route path="faq" element={<FAQ />}>
              {/* <Route
            <Route path="cs-center" element={<CustomerServices />}>
              <Route
                path=""
                element={<Navigate to={"announcement"} replace />}
              />
              {!isMobile && (
                <Route
                  path="announcement-detail/:id"
                  element={<AnnouncementDetail />}
                />
              )}
              <Route path=":type" element={<TabContent />} />
              <Route path="inquiry" element={<Inquiry />}>
                <Route
                  // disableScrollToTop={true}
                  path=":type"
                  element={<InquiryList />}
                />
              </Route> */}
            </Route>
            {/* {isMobile && (
              <Route path="cs-center/announcement-detail">
                <Route path=":id" element={<AnnouncementDetail />} />
              </Route>
            )} */}
            <Route path="not-login" element={<NotLogin />} />
            <Route path="handleSNS-new" element={<NotLoginInactive />} />
          </Route>
          <Route path="auth" element={<MainLayout />}>
            <Route path="" element={<UserAuth />} />
            {/* <Route path="login" element={<LoginForm />} /> */}
            <Route path="register" element={<RegisterForm />} />
            <Route path="create-profile" element={<CreateProfile />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="niceApi-form" element={<NiceApiForm />} />
          </Route>
          <Route path="" element={<MainLayout />}>
            <Route path={UrlInternal.BRAND} element={<Brand />} />
            <Route path={`:brandName`} element={<BrandDetail />} />
            <Route
              path={`:brandName/${UrlInternal.BRAND_DETAIL_COMMUNITY_REPORT}`}
              element={<ReportPageMobile />}
            />
            <Route
              path={`:brandName/${UrlInternal.BRAND_DETAIL_COMMUNITY_POST}`}
              element={<PostPageMobile />}
            />
            <Route
              path={`:brandName/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/:id`}
              element={<CommentPageMobile />}
            />
            <Route
              path={`:brandName/${UrlInternal.FEED_DETAIL}/:id`}
              element={<DetailFeedMobile />}
            />

            <Route
              path={UrlInternal.SEARCH_BRAND}
              element={<SearchFilterBrand />}
            />
            {/* <Route
              path={`${UrlInternal.FEED_DETAIL}/:id`}
              element={<BrandFeedDetail />}
            /> */}
            <Route path="search" element={<SearchDesktop />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="supplier-info/:brandName" element={<SupplierInfo />} />
          </Route>
          <Route path="search-mobile" element={<SearchMobile />} />
          <Route path="about" element={<LangdingPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
