import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
import BannerSlide from "./components/BannerSlide"
import Ranking from "./components/Ranking"
import Politicians from "./components/Politicians"
import styles from "./index.module.scss"
import RankingSlide from "./components/RankingSlide"
interface politicians {
  id: number;
  name: string;
  point: number;
  bgImageUrl: string;
  profileImageUrl: string;
  link: string;
}

interface RankingData {
  influence: any[];
  rising: any[];
  activity: any[];
  fanclub: any[];
  search: any[];
  hotIssue: any[];
}

interface BannerItem {
  id: number;
  party: string;
  title: string;
  description: string;
  imageUrl: string;
  backgroundColor: string;
}

function titleCase(str: string) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

function NewHomePage() {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const [politiciansData, setPoliticiansData] = useState<politicians[]>([])
  const [bannerData, setBannerData] = useState<BannerItem[]>([])
  const [rankingData, setRankingData] = useState<RankingData>({ influence: [], rising: [], activity: [], fanclub: [], search: [], hotIssue: [] });

  const getData = async () => {
    return {
      banner: [
        {
          "id": 1,
          "party": "국민의 힘",
          "title": "홍준표 팬클럽 오픈",
          "description": "정치인 홍준표를 사랑하는 우리만의 소통채널 오픈",
          "imageUrl": "/assets/images/banner1.png",
          "backgroundColor": "rgba(168, 35, 35, 0.6)",
          "link": "/hongjunpyo"
        },
        {
          "id": 2,
          "party": "더불어민주당",
          "title": "이재명 팬클럽 6주째 1위",
          "description": "정치인 이재명을 사랑하는 우리만의 소통채널",
          "imageUrl": "/assets/images/banner2.png",
          "backgroundColor": "rgba(40, 75, 228, 0.6)",
           "link": "/leejaemyeong"

        },
        {
          "id": 3,
          "party": "서울특별시",
          "title": "오세훈 소통창구 런칭",
          "description": "정치인 오세훈과 함께하는 서울의 미래",
          "imageUrl": "/assets/images/banner1.png",
          "backgroundColor": "#F0C431",
          "link": "/osehun"
        },
        {
          "id": 4,
          "party": "법무부",
          "title": "한동훈 지지 모임 결성",
          "description": "정치인 한동훈을 지지하는 열혈 서포터 모임",
          "imageUrl": "/assets/images/banner2.png",
          "backgroundColor": "#C8D5EB",
          "link": "/handonghun"
        }
      ],
      rankingData: {    
      "fanclub": [
        { "rank": 1, "name": "이재명 팬클럽", "points": 200, "imageUrl": "/assets//images/lee.png", "link": "/leejaemyeong" },
        { "rank": 2, "name": "홍준표 팬클럽", "points": 200, "imageUrl": "/assets//images/hong.png", "link": "/hongjunpyo" },
        { "rank": 3, "name": "한동훈 팬클럽", "points": 200, "imageUrl": "/assets//images/han.png", "link": "/handonghun" },
        { "rank": 4, "name": "우원식 팬클럽", "points": 200, "imageUrl": "/assets//images/woo.png", "link": "/wooyunseok" },
        { "rank": 5, "name": "오세훈 팬클럽", "points": 200, "imageUrl": "/assets//images/oh.png", "link": "/osehun" }
      ],
      "search": [
        { "rank": 1, "name": "이재명", "points": 200, "imageUrl": "/assets//images/lee.png", "link": "/leejaemyeong" },
        { "rank": 2, "name": "홍준표", "points": 200, "imageUrl": "/assets//images/hong.png", "link": "/hongjunpyo" },
        { "rank": 3, "name": "한동훈", "points": 200, "imageUrl": "/assets//images/han.png", "link": "/handonghun" },
        { "rank": 4, "name": "우원식", "points": 200, "imageUrl": "/assets//images/woo.png", "link": "/wooyunseok" },
        { "rank": 5, "name": "오세훈", "points": 200, "imageUrl": "/assets//images/oh.png", "link": "/osehun" }
      ],
      "hotIssue": [
        { "rank": 1, "name": "이재명", "points": 200, "imageUrl": "/assets//images/lee.png", "link": "/leejaemyeong" },
        { "rank": 2, "name": "홍준표", "points": 200, "imageUrl": "/assets//images/hong.png", "link": "/hongjunpyo" },
        { "rank": 3, "name": "오세훈", "points": 200, "imageUrl": "/assets//images/oh.png", "link": "/osehun" },
        { "rank": 4, "name": "한동훈", "points": 200, "imageUrl": "/assets//images/han.png", "link": "/handonghun" },
        { "rank": 5, "name": "우원식", "points": 200, "imageUrl": "/assets//images/woo.png", "link": "/wooyunseok" }
      ],
        "influence": [
          {
            "rank": 1,
            "name": "이재명",
            "arrow": "up",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          },
          {
            "rank": 2,
            "name": "홍준표",
            "arrow": "up",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 3,
            "name": "한동훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/han.png",
            "link": "/handonghun"
          },
          {
            "rank": 4,
            "name": "우원식",
            "arrow": "up",
            "profileImageUrl": "/assets/images/woo.png",
            "link": "/wooyunseok"
          },
          {
            "rank": 5,
            "name": "오세훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          },
          {
            "rank": 6,
            "name": "이재명",
            "arrow": "down",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          },
          {
            "rank": 7,
            "name": "홍준표",
            "arrow": "down",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 8,
            "name": "한동훈",
            "arrow": "down",
            "profileImageUrl": "/assets/images/han.png",
            "link": "/handonghun"
          },
          {
            "rank": 9,
            "name": "홍준표",
            "arrow": "down",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 10,
            "name": "이재명",
            "arrow": "down",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          }
        ],
        "rising": [
          {
            "rank": 1,
            "name": "홍준표",
            "arrow": "up",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 2,
            "name": "이재명",
            "arrow": "up",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          },
          {
            "rank": 3,
            "name": "오세훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          },
          {
            "rank": 4,
            "name": "한동훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/han.png",
            "link": "/handonghun"
          },
          {
            "rank": 5,
            "name": "우원식",
            "arrow": "up",
            "profileImageUrl": "/assets/images/woo.png",
            "link": "/wooyunseok"
          },
          {
            "rank": 6,
            "name": "오세훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          },
          {
            "rank": 7,
            "name": "홍준표",
            "arrow": "down",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 8,
            "name": "이재명",
            "arrow": "down",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          },
          {
            "rank": 9,
            "name": "홍준표",
            "arrow": "down",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 10,
            "name": "이재명",
            "arrow": "down",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          }
        ],
        "activity": [
          {
            "rank": 1,
            "name": "한동훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/han.png",
            "link": "/handonghun"
          },
          {
            "rank": 2,
            "name": "우원식",
            "arrow": "up",
            "profileImageUrl": "/assets/images/woo.png",
            "link": "/wooyunseok"
          },
          {
            "rank": 3,
            "name": "오세훈",
            "arrow": "up",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          },
          {
            "rank": 4,
            "name": "이재명",
            "arrow": "up",
            "profileImageUrl": "/assets/images/lee.png",
            "link": "/leejaemyeong"
          },
          {
            "rank": 5,
            "name": "홍준표",
            "arrow": "up",
            "profileImageUrl": "/assets/images/hong.png",
            "link": "/hongjunpyo"
          },
          {
            "rank": 6,
            "name": "한동훈",
            "arrow": "down",
            "profileImageUrl": "/assets/images/han.png",
            "link": "/handonghun"
          },
          {
            "rank": 7,
            "name": "우원식",
            "arrow": "down",
            "profileImageUrl": "/assets/images/woo.png",
            "link": "/wooyunseok"
          },
          {
            "rank": 8,
            "name": "오세훈",
            "arrow": "down",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          },
          {
            "rank": 9,
            "name": "우원식",
            "arrow": "down",
            "profileImageUrl": "/assets/images/woo.png",
            "link": "/wooyunseok"
          },
          {
            "rank": 10,
            "name": "오세훈",
            "arrow": "down",
            "profileImageUrl": "/assets/images/oh.png",
            "link": "/osehun"
          }
        ]
      },
      politiciansData: [
        {
          "id": 1,
          "name": "이재명 팬클럽",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_lee.png",     // 큰 배경 이미지
          "profileImageUrl": "/assets/images/lee.png",
          "link": "/leejaemyeong"
        },
        {
          "id": 2,
          "name": "홍준표",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_hong.png",
          "profileImageUrl": "/assets/images/hong.png",
          "link": "/hongjunpyo"
        },
        {
          "id": 3,
          "name": "한동훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_han.png",
          "profileImageUrl": "/assets/images/han.png",
          "link": "/handonghun"
        },
        {
          "id": 4,
          "name": "오세훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_oh.png",
          "profileImageUrl": "/assets/images/oh.png",
          "link": "/osehun"
        },
        {
          "id": 5,
          "name": "이재명 팬클럽",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_lee.png",     // 큰 배경 이미지
          "profileImageUrl": "/assets/images/lee.png",
          "link": "/leejaemyeong"
        },
        {
          "id": 6,
          "name": "홍준표",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_hong.png",
          "profileImageUrl": "/assets/images/hong.png",
          "link": "/hongjunpyo"
        },
        {
          "id": 7,
          "name": "한동훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_han.png",
          "profileImageUrl": "/assets/images/han.png",
          "link": "/handonghun"
        },
        {
          "id": 8,
          "name": "오세훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_oh.png",
          "profileImageUrl": "/assets/images/oh.png",
          "link": "/osehun"
        },
        {
          "id": 9,
          "name": "이재명 팬클럽",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_lee.png",     // 큰 배경 이미지
          "profileImageUrl": "/assets/images/lee.png",
          "link": "/leejaemyeong"
        },
        {
          "id": 10,
          "name": "홍준표",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_hong.png",
          "profileImageUrl": "/assets/images/hong.png",
          "link": "/hongjunpyo"
        },
        {
          "id": 11,
          "name": "한동훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_han.png",
          "profileImageUrl": "/assets/images/han.png",
          "link": "/handonghun"
        },
        {
          "id": 12,
          "name": "오세훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_oh.png",
          "profileImageUrl": "/assets/images/oh.png",
          "link": "/osehun"
        },
        {
          "id": 13,
          "name": "이재명 팬클럽",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_lee.png",     // 큰 배경 이미지
          "profileImageUrl": "/assets/images/lee.png",
          "link": "/leejaemyeong"
        },
        {
          "id": 14,
          "name": "홍준표",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_hong.png",
          "profileImageUrl": "/assets/images/hong.png",
          "link": "/hongjunpyo"
        },
        {
          "id": 15,
          "name": "한동훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_han.png",
          "profileImageUrl": "/assets/images/han.png",
          "link": "/handonghun"
        },
        {
          "id": 16,
          "name": "오세훈",
          "point": 200,
          "bgImageUrl": "/assets/images/bg_oh.png",
          "profileImageUrl": "/assets/images/oh.png",
          "link": "/osehun"
        }
      ]
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData()
      setPoliticiansData(data.politiciansData)
      setBannerData(data.banner)
      setRankingData(data.rankingData)
    }
    fetchData()
  }, [])

  return (
    <>
      {isMobile && <NavBarMb navType="home" pageName="MOAFRIENDS" />}
      <div className="relative flex flex-col">
          <BannerSlide bannerData={bannerData} />
      </div>
      <div className={styles.ranking}>
        <div className="container lg:pb-[139px] pb-[100px] order-5">
          <Ranking rankingData={rankingData} />           
       </div>
       </div>
       <div className="container lg:pb-[139px] pb-[100px] order-5">
          <RankingSlide rankingData={rankingData} />
        <div className="container lg:pb-[139px] pb-[100px] order-5">
          <Politicians politiciansData={politiciansData} />
        </div>
      </div>

    </>
  )
}
export default NewHomePage;

