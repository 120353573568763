import React from "react";
import styles from "./Politicians.module.scss";
import { Link } from "react-router-dom";

// type  for policitiansData
// "id": 1,
// "name": "이재명 팬클럽",
// "point": 200,
// "bgImageUrl": "/assets/images/bg_lee.png",     // 큰 배경 이미지
// "profileImageUrl": "/assets/images/lee.png"   
interface politicians {
    id: number;
    name: string;
    point: number;
    bgImageUrl: string;
    profileImageUrl: string;
    link: string;
}

interface PoliticiansProps {
  politiciansData: politicians[];
}

const Politicians: React.FC<PoliticiansProps> = ({ politiciansData }) => {
    return (
        <div className={styles.container}>
          <h2 className={styles.title}>당신의 정치인을 응원하세요<span>(24.12.16~23)</span></h2>
          <div className={styles.cardWrapper}>
            {politiciansData.map((item) => (
              <div key={item.id} className={styles.card}>
                {/* 배경 이미지 */}
                <div
                  className={styles.bgImage}
                  style={{ backgroundImage: `url(${item.bgImageUrl})` }}
                >
                  {/* 원형 프로필 */}
                  <Link to={item.link}>
                  <img
                    className={styles.profileImage}
                    src={item.profileImageUrl}
                    alt={item.name}
                  />
                  </Link>
                </div>
                {/* 이름 / 포인트 */}
                <Link to={item.link}>
                <div className={styles.info}>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.point}>{item.point} point</div>
                </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    };
    
    export default Politicians;