import { useEffect, useState } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  brandDataDetailSelect,
  getBrandDetailData,
  setBrandDetailData,
} from "src/features/brandData/brandDataSlice"

import { brandMappingData } from "src/api/brandApi"
import RegisterMembershipSuccess from "src/components/Modal/Brand/RegisterMembershipSuccess"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import { setDataBrandMapping } from "src/features/dataBrandMapping/dataBrandMappingSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import Community from "./Community"
import Feed from "./components/Feed"
import BrandHeader from "./components/Header"
import BrandLocation from "./components/Location"
import Shop from "./components/Shop"
import { getBrandDetail } from "src/services/Brand"
import { Spin } from "antd"
import { useTranslation } from "react-i18next"

const tablist = [
  {
    title: "Feeds",
    url: "feed",
    number: null,
  },
  {
    title: "Shop",
    url: "Shop",
    number: null,
  },
  {
    title: "Info.",
    url: "about-us",
    number: null,
  },
]

const tablistCommunity = [
  {
    title: "Feeds",
    url: "feed",
    number: null,
  },
  {
    title: "Community",
    url: "community",
    number: null,
  },
  {
    title: "Shop",
    url: "Shop",
    number: null,
  },
  {
    title: "Info.",
    url: "about-us",
    number: null,
  },
]

const BrandDetail: React.FC = () => {
  const data = useAppSelector(brandDataDetailSelect)
  const [dataDetailBrand, setDataDetailBrand] = useState<any>({})
  const dispatch = useAppDispatch()
  const { isMobile } = useIsMobile(1023)
  const params = useParams()
  const { brandName } = params
  const [searchParams, setSearchParams] = useSearchParams()
  const tabActive = searchParams.get("tab") || "feed"
  const navigate = useNavigate()
  const tablistsCheckCommunity =
    data?.enableCommunity != 0 ? tablistCommunity : tablist
  const tablists = data?.hasShop
    ? tablistsCheckCommunity
    : tablistsCheckCommunity.filter((item) => item.url !== "Shop")
  const { search } = useLocation()
  const commentID = searchParams.get("commentID")
  const postID = searchParams.get("postID")
  const accessToken = searchParams.get("accessToken")
  const widthBrandSite =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  const [dataBrand, setDataBrand] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    if (brandName) {
      ;(async function getBrandMappingData() {
        const resp: any = await brandMappingData({
          k: brandName,
        })
        if (resp.result) {
          setDataBrand(resp.data)
          dispatch(setDataBrandMapping(resp.data))
          // dispatch(getBrandDetailData(resp?.data?.brandId))

          const respBrandDetail: any = await getBrandDetail(
            resp?.data?.brandId,
            "10000",
          )
          if (respBrandDetail?.result) {
            dispatch(setBrandDetailData(respBrandDetail?.data))
            setDataDetailBrand(respBrandDetail?.data)
          } else {
            dispatch(setBrandDetailData(null))
            setDataDetailBrand({})
          }

          if ((commentID || postID) && widthBrandSite <= 475) {
            if (tabActive === "community") {
              commentID
                ? navigate(
                    `/${resp?.data?.userName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${postID}?commentID=${commentID}?accessToken=${accessToken}`,
                  )
                : navigate(
                    `/${resp?.data?.userName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${postID}?accessToken=${accessToken}`,
                  )
            }
            if (tabActive === "feed") {
              commentID
                ? navigate(
                    `/${resp?.data?.userName}/${UrlInternal.FEED_DETAIL}/${postID}?commentID=${commentID}?accessToken=${accessToken}`,
                  )
                : navigate(
                    `/${resp?.data?.userName}/${UrlInternal.FEED_DETAIL}/${postID}?accessToken=${accessToken}`,
                  )
            }
          } else {
            navigate(`/${resp?.data?.userName}${search}`, { replace: true })
          }

          if (isNaN(Number(brandName))) {
            setIsLoading(false)
          }
        }
      })()
    }
  }, [brandName, commentID, postID])

  const renderTab = (tabName: string | null) => {
    if (tabName === "feed") {
      return <Feed brandId={dataBrand?.brandId} />
    }
    if (tabName === "community") {
      return <Community />
    }
    if (tabName === "Shop") {
      return <Shop />
    }
    if (tabName === "about-us") {
      return (
        <BrandLocation
          locationList={data?.branches}
          defaultBanner={data?.banner}
        />
      )
    }
    return null
  }

  const getNewDataMembershipDetail = async () => {
    const respBrandDetail: any = await getBrandDetail(
      dataDetailBrand?.id,
      "10000",
    )
    if (respBrandDetail?.result) {
      dispatch(setBrandDetailData(respBrandDetail?.data))
      setDataDetailBrand(respBrandDetail?.data)
    } else {
      dispatch(setBrandDetailData(null))
      setDataDetailBrand({})
    }
  }

  return (
    <>
      {isLoading && !dataDetailBrand && !isNaN(Number(brandName)) && (
        <Spin>
          <div className="min-h-[100vh]"></div>
        </Spin>
      )}

      <RegisterMembershipSuccess />

      <div className="pb-[43px] md:pb-[183px] lg:pt-[60px]">
        <div className="container md:px-[16px] px-0">
          {isMobile ? (
            <NavBarMb
              pageName={dataDetailBrand?.brandName}
              csGoback={() => navigate(`/${UrlInternal.BRAND}`)}
            />
          ) : (
            <div className="font-pretendard700 text-[24px] leading-[24px] lg:mb-[40px] pb-[24px] border-b-[2px] border-b-primary">
              {dataDetailBrand?.brandName}
            </div>
          )}
          <div className="lg:mt-[50px]">
            {/* <BrandHeader
              // id={dataDetailBrand?.id}
              logoImage={dataDetailBrand?.logoImage}
              brandName={dataDetailBrand?.brandName}
              banner={dataDetailBrand?.banner}
              brandSlogan={dataDetailBrand?.slogan}
              data={dataDetailBrand}
              getNewDataMembershipDetail={getNewDataMembershipDetail}
            /> */}
            <div className="md:pt-[130px] pt-[50px] md:px-[41px] px-[24px]">
              {/**tab pc */}
              <div className="sm:flex hidden pb-[18px] mb-[30px] border-b-[1.863px] border-primary">
                {tablists.map((item) => (
                  <div
                    key={item.url}
                    className="leading-[18px] px-[16px] border-l-[1px] border-gray5 first:pl-0 first:border-none"
                  >
                    <button
                      className={`text-[18px] leading-[18px] ${
                        tabActive === item.url
                          ? "font-pretendard700"
                          : "font-pretendard400"
                      }`}
                      onClick={() => {
                        searchParams.set("tab", item.url)
                        setSearchParams(searchParams)
                      }}
                    >
                      {t(item.title)}
                    </button>
                  </div>
                ))}
              </div>
              {/**tab mobile */}
              <div
                className={`sm:hidden flex py-[15px] bg-[#000] mx-[-24px] ${
                  tabActive !== "feed" ? "mb-[30px]" : "mb-[1px]"
                }`}
              >
                {tablists.map((item) => (
                  <div
                    key={item.url}
                    className="leading-[16px] px-[16px] border-l-[1px] border-[#757575] first:pl-[24px] first:border-none"
                  >
                    <button
                      className={`text-[#fff] text-[18px] leading-[18px] ${
                        tabActive === item.url
                          ? "font-pretendard700"
                          : "font-pretendard400"
                      }`}
                      onClick={() => {
                        searchParams.set("tab", item.url)
                        setSearchParams(searchParams)
                      }}
                    >
                      {item.title}
                    </button>
                  </div>
                ))}
              </div>
              {renderTab(tabActive)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrandDetail
