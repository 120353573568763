import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import { loginInfo } from "src/pages/Auth/LoginRegister/LoginForm"
import api from "src/services/axiosInstance"

export const userApi = {
  login(payload: loginInfo) {
    return api.request({
      url: ApiUrl.LOGIN,
      method: "POST",
      data: payload,
    })
  },
  getMe() {
    return api.request({
      url: ApiUrl.GET_ME,
      method: "GET",
      tokenRequired: true,
    })
  },
  getOtp() {
    return api.request({
      url: ApiUrl.GET_OTP,
      method: "POST",
      tokenRequired: true,
    })
  },
  getOtpDeleteAcc() {
    return api.request({
      url: ApiUrl.GET_OTP_DELETE,
      method: "POST",
      tokenRequired: true,
    })
  },
  updateAccount(payload: { avatar: string }) {
    return api.request({
      url: ApiUrl.UPDATE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  updateUserInfo(payload: {
    displayName: string
    birthDate: string
    gender: string
    verifiedPhoneNumber: string
  }) {
    return api.request({
      url: ApiUrl.UPDATE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
    updateAddress(payload: {
    address: string
    postCode: string
    moreDetail: string
  }) {
    return api.request({
      url: ApiUrl.UPDATE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  updatePolicy(payload: {
    acceptInfoCollection: boolean
    smsReceived: boolean
    emailReceived: boolean
  }) {
    return api.request({
      url: ApiUrl.UPDATE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  deleteAccount(payload: { password: string }) {
    return api.request({
      url: ApiUrl.DELETE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  deleteAccountSns(payload: { otp: number }) {
    return api.request({
      url: ApiUrl.DELETE_ACCOUNT_SNS,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  deactiveAccount(payload: { password: string }) {
    return api.request({
      url: ApiUrl.DEACTIVE_ACCOUNT,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  deactiveAccountSns(payload: { otp: number; trxUID?: string }) {
    return api.request({
      url: ApiUrl.DEACTIVE_ACCOUNT_SNS,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
  getAllSubscribed() {
    return api.request({
      url: ApiUrl.GET_SUBCRIBED,
      method: "GET",
      tokenRequired: true,
    })
  },
  getVoucherNearlyExpired(params: {
    page: string | number
    size: string | number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.LIST_VOUCHER,
      method: "GET",
      tokenRequired: true,
      params: { ...params, filterType: "30DLEFT" },
    })
  },
}
