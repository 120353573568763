import Modal from "@components/Modal"
import { Form } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "src/app/hooks"
import { AppDispatch } from "src/app/store"
import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import Selection from "src/components/Selection"
import useIsMobile from "src/customHook/useIsMobile"
import {
  loginSuccess,
  openCreateAccountSuccess,
} from "src/features/user/userSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import { singUp } from "src/services/User"
import { b64DecodeUnicode } from "src/util"
import dayjs from "dayjs"
import { DatePicker, Select } from "antd"

type Props = {}

function CreateProfile({}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useAppDispatch()
  const [searchParam, setSearchParam] = useSearchParams()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [messErr, setMessErr] = useState<string>("")
  const [submittable, setSubmittable] = useState(true)
  const [selectionData, setSelectionData] = useState({
    getExclusive: true,
    getSuggest: true,
  })
  const [form] = Form.useForm()
  const resultSns = searchParam.get("result")
  // Lấy data được trả về từ SNS qua params trên url
  const resSns = searchParam.get("data")
  const dataSns = useMemo(() => {
    if (resultSns === "true" && !!resSns) {
      const data = JSON.parse(
        b64DecodeUnicode(resSns.replace(" ", "+") as string),
      )
      form.setFieldValue("email", data.email)
      return data
    }
    return {}
  }, [resSns, resultSns])
  const handleOkModal = () => {
    navigate("/")
  }
  const isRegisterSns = useMemo(() => {
    return !!dataSns.sns_id
  }, [dataSns])
  const onFinish = async (data: Record<string, string>) => {
    try {
      let resp: any = await singUp({
        email: data.email,
        password: data.password,
        displayName: data.fullname || dataSns.display_name,
        gender: data.gender || dataSns.gender,
        birthDate: data.birthday ? dayjs(data.birthday).format('YYYY-MM-DD') : dataSns.birth_date,
        phoneNumber: (data.phone || dataSns?.phone_number)?.replace(/[^0-9]/g, ""),
        emailReceive: selectionData.getExclusive,
        accountType: dataSns.account_type,
        snsId: dataSns.sns_id,
      })

      if (resp.result) {
        // setIsOpenModal(true)
        try {
          dispatch(loginSuccess(resp.data))
          dispatch(openCreateAccountSuccess(true))
          navigate("/")
        } catch (error) {
          console.error(error)
          // setIsOpenModal(true)
        }
      } else {
        setMessErr(t(resp.message))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const values = Form.useWatch([], form)
  const { isMobile } = useIsMobile(1023)
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      },
    )
  }, [form, values])

  return (
    <div>
      {isMobile && <NavBarMb pageName={t("Create Profile")} />}
      <div className="flex-1 bg-no-repeat bg-right-bottom lg:pt-[74px] pt-[83px]">
        <div className="container max-w-[511px] rounded-t-[20px] bg-transparent overflow-hidden">
          <div>
            <div
              className={`w-full flex flex-col justify-end text-center gap-[8px]`}
            >
              <p className="text-[24px] font-pretendard500 text-black leading-normal">
                {t("회원가입 정보 입력")}
              </p>
              <p className="text-[16px] font-pretendard400 text-gray1 leading-[100%]">
                {t("Let others get to know you better")}
              </p>
            </div>
          </div>
          <div className="lg:mt-[47px] mt-[68px]">
            <Form
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              form={form}
            >
              <div>
                <CSInput
                  placeholder={t("아이디")}
                  name="email"
                  label="이메일주소"
                  defaultValue={dataSns?.email}
                  rules={[
                    {
                      type: "email",
                      message:
                        "Please enter a valid email address. Ex: abc@xyz.kr",
                    },
                    { required: true },
                  ]}
                />
              </div>
              {!isRegisterSns && (
                <div className="grid grid-cols-1 gap-[17px]">
                  <CSInput
                    placeholder={t("비밀번호 8자 이상 영문, 숫자, 특수문자")}
                    className="has-show-password"
                    inputType="password"
                    name="password"
                    label={t("비밀번호")}
                    rules={[
                      {
                        required: true,
                        message: t("Please enter password!"),
                      },
                      {
                        min: 8,
                        message: t(
                          "Passwords must include 8 characters of uppercase and lowercase letters, numbers, and special characters.",
                        ),
                      },
                      {
                        pattern:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
                        message: t(
                          "Passwords must include 8 characters of uppercase and lowercase letters, numbers, and special characters.",
                        ),
                      },
                    ]}
                  />
                  <Form.Item
                    name="confirm-pass"
                    dependencies={["password"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!",
                            ),
                          )
                        },
                      }),
                    ]}
                  >
                    <CSInput
                      placeholder={t("비밀번호 8자 이상 영문, 숫자, 특수문자")}
                      className="has-show-password"
                      name="confirm-pass"
                      inputType="password"
                      label={t("비밀번호 확인")}
                      rules={[
                        {
                          required: true,
                          message: "Please enter confirm password",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              )}
              <div className="">
                {/* <div className="grid">
                  <CSInput
                    name="fullname"
                    label={t("이름")}
                    placeholder={t("김동욱")}
                    disabled
                    initialValue={dataSns?.display_name}
                  />
                  <CSInput
                    name="phone"
                    label={t("휴대폰번호")}
                    placeholder={t("휴대폰번호")}
                    textFont="Monserat-500"
                    disabled
                    initialValue={dataSns?.phone_number?.replaceAll("-", "")}
                  />
                  <div className="grid lg:grid-cols-2 gap-[8px]">
                    <CSInput
                      name="birthday"
                      label={t("생년월일")}
                      placeholder={t("생년월일")}
                      disabled
                      initialValue={dataSns?.birth_date}
                    />
                    <CSInput
                      name="gender"
                      label={t("성별")}
                      placeholder={t("성별")}
                      disabled
                      initialValue={dataSns?.gender?.toUpperCase()}
                    />
                  </div>
                </div> */}
                <div className="grid">
                  <CSInput
                    name="fullname"
                    label={t("이름")}
                    placeholder={t("이름을 입력해주세요")}
                    // disabled
                    initialValue={dataSns?.display_name}
                  />
                  <CSInput
                    name="phone"
                    label={t("휴대폰번호")}
                    placeholder={t("휴대폰번호만 입력해주세요 (예: 01012345678)")}
                    textFont="Monserat-500"
                    // disabled
                    initialValue={dataSns?.phone_number?.replaceAll("-", "")}
                  />
                  <div className="grid lg:grid-cols-2 gap-[8px]">
                    <Form.Item
                      name="birthday"
                      label={t("생년월일")}
                      initialValue={dataSns?.birth_date ? dayjs(dataSns.birth_date) : undefined}
                    >
                      <DatePicker 
                        placeholder={t("생년월일")}
                        format="YYYY-MM-DD"
                        style={{borderRadius: '0px'}}
                        className="h-[56px] border-black border-solid w-full"
                      />
                    </Form.Item>
                    <Form.Item 
                      name="gender" 
                      label={t("성별")}
                      initialValue={dataSns?.gender?.toUpperCase()}
                      
                    >
                      <Select
                        placeholder={t("성별")}
                        className="h-[56px] border-black border-solid"
                        style={{borderRadius: '0px', borderColor: '#000'}}
                        options={[
                          { label: "남", value: "MALE" },
                          { label: "여", value: "FEMALE" }
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="lg:mt-[30px] mt-[15px]">
                  <Selection
                    selectOption={[
                      {
                        label: t(
                          "(선택) SMS, 이메일로 상품 및 이벤트 정보를 받겠습니다.",
                        ),
                        name: "getExclusive",
                        defaultChecked: selectionData.getExclusive,
                      },
                    ]}
                    onSelectChange={(data: Record<string, boolean>) =>
                      setSelectionData((prev) => ({ ...prev, ...data }))
                    }
                  />
                </div>

                <div className="flex justify-center md:mt-[60px] mt-[31px]">
                  <Button type="submit" theme="main" labelSize={18}>
                    {t("Create Profile")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false)
        }}
      >
        <p className="text-center text-[16px]">Account created successfully</p>
        <div className="mt-[30px] w-full flex justify-end">
          <Button theme="main" onClick={handleOkModal}>
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        open={messErr !== ""}
        onCancel={() => {
          setMessErr("")
        }}
      >
        <p className="text-center text-[16px] whitespace-pre-line">{messErr}</p>
      </Modal>
    </div>
  )
}

export default CreateProfile
