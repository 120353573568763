import React from "react";
import styles from "./RankingSlide.module.scss";

// Swiper import
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css"; 
import "swiper/css/effect-cards"; 
import { Link } from "react-router-dom";

interface RankingData {
  fanclub: any[];
  search: any[]; 
  hotIssue: any[];
  activity: any[];
  rising: any[];
  influence: any[];
}

interface SlideCardItem {
    rank: number;
    name: string;
    points: number;
    imageUrl: string;
  }
  
  interface SlideCardProps {
    title: string;       // 예: "팬클럽 활동"
    date?: string;       // 예: "(25.01.09)"
    data: SlideCardItem[];  // 5명 정보
  }
  
  // 5명을 2/2/1씩 끊어서 배열 3개로 만들어주는 헬퍼
  function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }
  const RankingSlide: React.FC<{rankingData: RankingData}> = ({rankingData}) => {
    const ele: Array<keyof typeof titles> = ["fanclub", "search", "hotIssue"];
    const titles = {
        fanclub: "팬클럽 활동",
        search: "실시간 검색",
        hotIssue: "핫이슈"
    };

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>당신의 정치인을 응원하세요<span>(24.12.16 ~ 23)</span></h1>
        
        <div className={styles.sliderContainer}>
        {ele.map((category) => (
          <div key={category} className={styles.sliderSection}>
            <h2 className={styles.sectionTitle}>{titles[category]}<span>(2025.01.05)</span></h2>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className={styles.swiper}
            >
              {rankingData[category].map((item) => (
                <SwiperSlide key={item.rank}>
                  <div className={styles.slideInner}>
                    <div className={styles.card}>
                      <img
                        src={item.imageUrl}
                        alt={item.name}
                        className={styles.cardImage}
                      />
                      <Link to={item.link}>
                      <div className={styles.cardInfo}>
                        <div className={styles.rank}>{item.rank}위</div>
                        <div className={styles.name}>{item.name}</div>
                        <div className={styles.points}>{item.points} point</div>  
                      </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))}
        </div>
      </div>
    );
}
export default RankingSlide;