import { useEffect, useMemo, useState } from "react"
import UploadAvatar from "src/components/UploadAvt"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import { userApi } from "src/api/userApi"
import { Form, message, Select } from "antd"
import { updateAvatar } from "src/features/user/userSlice"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { useCountTime } from "src/customHook/useCountTime"
import UpdatePassword from "src/components/Modal/auth/UpdatePassword"
import { secondToMinute } from "src/util"
import { useTranslation } from "react-i18next"
import { listDefaultAvt, socialListIcon } from "../config"
import CSInput from "src/components/Input/Index"
import { Postcode } from "src/components/PostCode"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import DeactiveAccountSns from "src/components/Modal/DeactiveAccountSns"
import DeactiveAccount from "src/components/Modal/DeactiveAccount"
import DeleteAccountSns from "src/components/Modal/DeleteAccountSns"
import DeleteAccount from "src/components/Modal/DeleteAccount"
import DeleteRequestSuccess from "src/components/Modal/DeleteRequestSuccess"
import ForgotPassword from "src/components/Modal/auth/ForgotPassword"
import Selection from "src/components/Selection"
import useIsMobile from "src/customHook/useIsMobile"

type TSOCIAL = "NAVER" | "KAKAO" | "APPLE" | "GG"
type TGENDER = "MALE" | "FEMALE"

const EMAIL_SEND_KEY = "email_send"

const MyChangeInfo = () => {
  const { isMobile } = useIsMobile(1023)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [openDeactivate, setOpenDeactivate] = useState<boolean>(false)
  const [openDeactivateSns, setOpenDeactivateSns] = useState<boolean>(false)
  const [openDeleteAcc, setOpenDeleteAcc] = useState<boolean>(false)
  const [openDeleteAccSns, setOpenDeleteAccSns] = useState<boolean>(false)
  const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState<boolean>(false)
  const dataUser = useAppSelector(userDataSelect)
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false)
  const [openUpdatePassword, setOpenUpdatePassword] = useState<boolean>(false)
  const [registerChecked, setRegisterChecked] = useState({
    acceptInfoCollection: false,
    smsReceive: false,
    emailReceived: false,
  })
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 })
  const { countTime } = useCountTime(0, EMAIL_SEND_KEY)
  const iconType: TSOCIAL =
    dataUser?.accountType !== "NORMAL" ? dataUser?.accountType : ""
  const genderType: TGENDER = dataUser?.gender
  const [userForm] = Form.useForm()
  
  useEffect(() => {
    if (dataUser) {
      setRegisterChecked({
        acceptInfoCollection: dataUser?.acceptInfoCollection,
        smsReceive: dataUser?.smsReceive,
        emailReceived: dataUser?.emailReceive,
      })
      const listDataKey = Object.keys(dataUser)
      listDataKey.map((item) => {
        return form.setFieldValue(item, dataUser[item])
      })
    }
  }, [dataUser, form])

  useEffect(() => {
    if (dataUser) {
      userForm.setFieldsValue({
        displayName: dataUser.displayName,
        birthDate: dataUser.birthDate,
        gender: dataUser.gender,
        verifiedPhoneNumber: dataUser.verifiedPhoneNumber,
      });
    }
  }, [dataUser, userForm]);

  const errorMess = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    })
  }
  const successMess = (mess: string) => {
    messageApi.open({
      type: "success",
      content: mess,
    })
  }
  const handleChangeImage = (file: File) => {
    console.log(file)
  }

  const handleUpdateAvatar = async (file: File) => {
    // console.log('value', file);
    const formData = new FormData()
    formData.append("file", file)
    let imageUrl: string
    try {
      const resp: any = await uploadSingleFile(formData)
      if (resp.error) {
        errorMess("Upload avatar failed")
        setIsUploadDone(false)
        return
      }
      imageUrl = resp.imageUrl
      const { error }: any = await userApi.updateAccount({ avatar: imageUrl })
      if (error) {
        errorMess("Upload avatar failed")
        setIsUploadDone(false)
        return
      }
      setIsUploadDone(true)
      dispatch(updateAvatar(imageUrl))
    } catch (error) {
      console.log(error)
    }
  }

  const isReadySend = useMemo(() => {
    return countTime <= 0
  }, [countTime > 0])

  const onFinish = async (values: any) => {
    try {
      const resp: any = await userApi.updateAddress({
        address: values.address,
        postCode: values.postCode,
        moreDetail: values.moreDetail,
      })
      if (resp.result) {
        successMess("Address updated successfully.")
        dispatch(getUserInfo())
        return
      } else {
        errorMess("Address updated failed")
      }
    } catch (error) {
      console.log(error)
      errorMess("Address updated failed")
    }
  }

  const handleUpdatePolicy = async () => {
    try {
      const resp: any = await userApi.updatePolicy({
        acceptInfoCollection: registerChecked.acceptInfoCollection,
        smsReceived: registerChecked.smsReceive,
        emailReceived: registerChecked.emailReceived,
      })
      if (resp.result) {
        successMess("Privacy policy updated successfully.")
        dispatch(getUserInfo())
        return
      } else {
        errorMess("Privacy policy updated failed")
      }
    } catch (error) {
      console.log(error)
      errorMess("Privacy policy updated failed")
    }
  }

  useEffect(() => {
    if (searchParams.get("status")) {
      if (dataUser.accountType === "NORMAL") {
        setOpenDeactivate(true)
      } else {
        setOpenDeactivateSns(true)
      }
    }
  }, [searchParams.get("status")])

  const handleCloseDeactiveModal = () => {
    setOpenDeactivate(false)
    searchParams.delete("status")
    setSearchParams(searchParams)
  }
  const handleCloseDeactiveSnsModal = () => {
    setOpenDeactivateSns(false)
    searchParams.delete("status")
    setSearchParams(searchParams)
  }

  const handleOpenDeactiveModal = () => {
    if (dataUser.accountType === "NORMAL") {
      setOpenDeactivate(true)
    } else {
      setOpenDeactivateSns(true)
    }
  }

  const handleOpenDeleteModal = () => {
    if (dataUser.accountType === "NORMAL") {
      setOpenDeleteAcc(true)
    } else {
      setOpenDeleteAccSns(true)
    }
  }

  const handleOnSelectChange = (data: Record<string, boolean>) => {
    setRegisterChecked((prev) => ({ ...prev, ...data }))
  }

  const handleUpdateUserInfo = async (values: any) => {
    try {
      const resp: any = await userApi.updateUserInfo({
        displayName: values.displayName,
        birthDate: values.birthDate,
        gender: values.gender,
        verifiedPhoneNumber: values.verifiedPhoneNumber,
      });
      
      if (resp.result) {
        successMess("회원정보가 성공적으로 수정되었습니다.");
        dispatch(getUserInfo());
        return;
      } else {
        errorMess("회원정보 수정에 실패했습니다.");
      }
    } catch (error) {
      console.log(error);
      errorMess("회원정보 수정에 실패했습니다.");
    }
  };

  console.log("dataUser", dataUser)

  return (
    <>
      {openDeactivateSns && (
        <DeactiveAccountSns
          isOpen={openDeactivateSns}
          handleClose={handleCloseDeactiveSnsModal}
          // timeOtp={timeOtp}
        />
      )}
      {openDeactivate && (
        <DeactiveAccount
          isOpen={openDeactivate}
          handleClose={handleCloseDeactiveModal}
          handleOpenForgotPassword={() => {
            handleCloseDeactiveModal()
            setOpenForgot(true)
          }}
        />
      )}

      {openDeleteAccSns && (
        <DeleteAccountSns
          isOpen={openDeleteAccSns}
          handleClose={() => setOpenDeleteAccSns(false)}
          handleOpenSuccess={() => {
            setOpenDeleteAccSns(false)
            setOpenDeleteSuccess(true)
            getUserInfo()
          }}
          // timeOtp={timeOtp}
        />
      )}
      {openDeleteAcc && (
        <DeleteAccount
          isOpen={openDeleteAcc}
          handleClose={() => setOpenDeleteAcc(false)}
          handleOpenSuccess={() => {
            setOpenDeleteAcc(false)
            setOpenDeleteSuccess(true)
            getUserInfo()
          }}
          handleOpenForgotPassword={() => {
            setOpenDeleteAcc(false)
            setOpenForgot(true)
          }}
        />
      )}

      <DeleteRequestSuccess
        isOpen={openDeleteSuccess}
        handleClose={() => setOpenDeleteSuccess(false)}
      />
      <ForgotPassword
        isOpen={isOpenForgot}
        handleClose={() => setOpenForgot(false)}
      />
      <UpdatePassword
        isOpen={openUpdatePassword}
        handleClose={() => setOpenUpdatePassword(false)}
        userName={dataUser?.userName}
      />
      <div className="font-pretendard700 lg:text-[24px] text-[18px] lg:leading-[24px] leading-[18px] lg:pb-[32px] pb-[28px] mb-[28px] border-b-[1px] border-b-primary">
        {t("회원정보 수정")}
      </div>
      <div className="flex flex-col mb-[28px]">
        <p className="font-pretendard600 text-[18px] leading-[18px] mb-[28px]">
          {t("로그인 정보")}
        </p>
        <div className="w-[80px] h-[80px]">
          <UploadAvatar
            onImageChange={(file) => {
              handleChangeImage(file)
            }}
            initImage={dataUser?.avatar || listDefaultAvt[genderType]}
            onModalOk={handleUpdateAvatar}
            uploadDone={isUploadDone}
          />
        </div>
      </div>

      <div className="flex flex-col border-b-[1px] border-b-primary mb-[28px]">
        {dataUser?.accountType === "NORMAL" ? (
          <>
            <div className="flex flex-col gap-[8px] mb-[36px]">
              <p className="text-[14px] leading-[14px]">{t("아이디")}</p>
              <p className="text-[14px] leading-[14px] font-pretendard700">
                {dataUser?.email}
              </p>
            </div>
            <div className="mb-[50px]">
              <div className="flex justify-between items-center lg:max-w-[360px]">
                <div className="flex flex-col gap-[8px]">
                  <p className="text-[14px] leading-[14px]">{t("비밀번호")}</p>
                  <p className="text-[14px] leading-[14px] font-pretendard700">
                    ***************
                  </p>
                </div>
                <button
                  className={`h-[52px] px-[32px] text-[16px] border-[1px] border-primary font-pretendard400 ${
                    !isReadySend && "bg-gray1"
                  }`}
                  onClick={() => setOpenUpdatePassword(true)}
                  disabled={!isReadySend}
                >
                  {t("변경")}
                </button>
              </div>
              {!isReadySend && (
                <p className="text-gray1 text-[14px] mt-1">
                  {t("DIDN_T_RECEIVE_RESEND_AFTER_01_00")}
                  <span className="text-primary font-pretendard700 ml-1">
                    {secondToMinute(countTime)}
                  </span>
                </p>
              )}
            </div>
          </>
        ) : (
          dataUser?.userRole !== "BRAND" && (
            <div className="flex flex-col gap-[10px] mb-[40px]">
              <p className="text-[14px] leading-[14px]">SNS 로그인</p>
              <p className="text-[14px] leading-[14px] font-pretendard700 flex gap-[12px] items-center">
                {socialListIcon[iconType]}
                {dataUser?.snsId}
              </p>
            </div>
          )
        )}
      </div>
      <div className="flex flex-col border-b-[1px] border-b-gray5 mb-[28px]">
        <p className="font-pretendard600 text-[18px] leading-[18px] mb-[28px]">
          {t("회원 정보")}
        </p>
        
        <Form
          form={userForm}
          onFinish={handleUpdateUserInfo}
          className="flex flex-col gap-[16px] mb-[50px]"
        >
          <div className="flex gap-[10px] lg:w-[362px]">
            <p className="text-[14px] leading-[14px] w-[67px] flex-none">
              {t("성명")}
            </p>
            <Form.Item name="displayName" className="flex-1 mb-0 lg:w-[362px]">
              <CSInput 
                name="displayName"
                inputHeight={48}
                borderColor="#D4D4D4"
                inputClassName="font-pretendard700"
                className="mb-0"
              />
            </Form.Item>
          </div>
          <div className="flex gap-[10px]  lg:w-[362px]">
            <p className="text-[14px] leading-[14px] w-[67px] flex-none">
              {t("연락처")}
            </p>
            <Form.Item name="verifiedPhoneNumber" className="flex-1 lg:w-[362px]">
              <CSInput 
                name="verifiedPhoneNumber"
                inputHeight={48}
                borderColor="#D4D4D4"
                inputClassName="font-pretendard700"
                className="mb-0"
              />
            </Form.Item>
          </div>
          <div className="flex gap-[10px]  lg:w-[362px]">
            <p className="text-[14px] leading-[14px] w-[67px] flex-none">
              {t("생일")}
            </p>
            <Form.Item name="birthDate" className="flex-1 mb-0 lg:w-[362px]">
              <CSInput 
                name="birthDate"
                type="date"
                inputHeight={48}
                borderColor="#D4D4D4"
                inputClassName="font-pretendard700"
                  className="mb-0"
              />
            </Form.Item>
          </div>
          <div className="flex gap-[10px]  lg:w-[362px]">
            <p className="text-[14px] leading-[14px] w-[67px] flex-none">
              {t("성별")}
            </p>
            <Form.Item name="gender" className="flex-1 mb-0 lg:w-[362px]">
              <Select className="lg:w-[362px] h-[48px] border border-[#D4D4D4] px-2 font-pretendard700 mb-0">    
                <Select.Option value="MALE">{t("MALE")}</Select.Option>
                <Select.Option value="FEMALE">{t("FEMALE")}</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <button
            type="submit"
            className="h-[48px] lg:w-[362px] w-full text-[14px] bg-primary text-primaryWhite font-pretendard700 mt-[20px]"
          >
            {t("저장하기")}
          </button>
        </Form>
      </div>

      <div className="flex flex-col border-b-[1px] border-b-primary mb-[28px]">
        <p className="font-pretendard600 text-[14px] leading-[14px] mb-[12px]">
          {t("주소 정보")}
        </p>
        <div className="flex flex-col gap-[16px] mb-[50px]">
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            requiredMark={false}
            name="validateOnly"
          >
            <div className="!mb-[12px]">
              <CSInput
                name="postCode"
                inputHeight={48}
                className="inline-block lg:w-[224px] w-postCodeW !my-0"
                borderColor="#D4D4D4"
                inputClassName="font-pretendard700"
                placeholder={t("우편번호")}
                disabled={true}
              />
              <div className="inline-block w-[128px] ml-[10px] !my-0">
                <Postcode
                  type="button"
                  btnClassName="w-[128px] h-[48px] border-[1px] border-primary font-pretendard400"
                  wrapClassName="!mb-0"
                  name="addressInfo"
                  label={t("우편번호 검색")}
                  placeholder={t("우편번호 검색")}
                  onCompleteAddress={(value: any) => {
                    form.setFieldValue("postCode", value?.zonecode)
                    form.setFieldValue("address", value?.address)
                  }}
                />
              </div>
            </div>
            <CSInput
              name="address"
              inputHeight={48}
              className="!mb-[12px] lg:w-[362px]"
              borderColor="#D4D4D4"
              inputClassName="font-pretendard700"
              placeholder={t("주소")}
              disabled={true}
            />
            <CSInput
              name="moreDetail"
              inputHeight={48}
              className="!mb-[12px] lg:w-[362px]"
              borderColor="#D4D4D4"
              inputClassName="font-pretendard700"
              placeholder={t("상세 주소")}
            />
            <button className="h-[48px] lg:w-[362px] w-full text-[14px] bg-primary text-primaryWhite font-pretendard700">
              {t("저장하기")}
            </button>
          </Form>
        </div>
      </div>

      <div className="flex flex-col border-b-[1px] border-b-primary pb-[50px] mb-[28px]">
        <div className="pb-[18px] border-b-[1px] border-b-gray5">
          <Selection
            width={26}
            height={26}
            checkmarkSize={16}
            labelStyle={{
              fontSize: 16,
              color: "#000",
              fontFamily: "Pretendard-400",
            }}
            onSelectChange={(data) => {
              handleOnSelectChange(data)
            }}
            selectOption={[
              {
                label: (
                  <>
                    (선택) 개인정보 수집 및 이용 동의{" "}
                    <Link
                      to={"/privacy-policy"}
                      className="underline text-gray1"
                      target={isMobile ? "_self" : "_blank"}
                    >
                      보기
                    </Link>
                  </>
                ),
                name: "acceptInfoCollection",
                defaultChecked: registerChecked.acceptInfoCollection,
              },
            ]}
          />
        </div>
        <div className="flex gap-[60px] my-[18px]">
          <Selection
            width={16}
            height={16}
            checkmarkSize={12}
            labelStyle={{
              fontSize: 14,
              color: "#000",
              fontFamily: "Pretendard-400",
            }}
            onSelectChange={(data) => {
              handleOnSelectChange(data)
            }}
            selectOption={[
              {
                label: "문자메시지",
                name: "smsReceive",
                defaultChecked: registerChecked.smsReceive,
              },
            ]}
          />
          <Selection
            width={16}
            height={16}
            checkmarkSize={12}
            labelStyle={{
              fontSize: 14,
              color: "#000",
              fontFamily: "Pretendard-400",
            }}
            onSelectChange={(data) => {
              handleOnSelectChange(data)
            }}
            selectOption={[
              {
                label: "이메일",
                name: "emailReceived",
                defaultChecked: registerChecked.emailReceived,
              },
            ]}
          />
        </div>
        <p className="text-[12px] text-gray1 mb-[20px]">
          서비스의 중요 안내사항 및 주문/배송에 대한 정보는 위 수신 여부와
          관계없이 발송됩니다.
        </p>
        <button
          onClick={handleUpdatePolicy}
          className="h-[48px] lg:w-[362px] w-full text-[14px] bg-primary text-primaryWhite font-pretendard700"
        >
          저장하기
        </button>
      </div>
      <div className="flex gap-[65px]">
        <button
          className="text-[14px] underline"
          onClick={handleOpenDeactiveModal}
        >
          {t("비활성화하기")}
        </button>
        <button
          className="text-[14px] underline"
          onClick={handleOpenDeleteModal}
        >
          {t("회원탈퇴하기")}
        </button>
      </div>
      {contextHolder}
    </>
  )
}

export default MyChangeInfo
